.profileContainer{
    width: 98.5%;
    margin-left: 0.6rem;
    border-radius: 5px;
    background-color: white;
    display: flex;
height: 100%;
    flex-direction: column;
}
.profileNameImageConatiner{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.profileFirstName{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100px;
    height: auto;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.profileFirstNameValue{
    width: 50%;
    text-align: left;
    font-family: 'Varela Round', sans-serif;

}
.profileNameConatiner{
    width: 50%;
}
.profileImageConatiner{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.imageBox{
    border: 2px solid black;
    border-radius: 50%;
    height: 250px;
    width: 250px;
    margin-top: 20px;
}

.inputFileImage{
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputImageButton{
    height: 40px;
    width: 140px;
    text-align: center;
    border-radius: 10px;
    padding-top: 11px;
    border: 1px lightblue solid;
    background-color: soli;
    color: black;
    box-shadow: 2px 2px teal;
    font-family: 'Varela Round', sans-serif;
    margin-top: 20px;
    cursor: pointer;

}
.profileInputLinesContainer{
    display: flex;
   
    flex-direction: column;
    width: 100%;
}
.profileSessionHeadingInput{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

}
.profilesessionInputLine{
    width: 50%;
    /* min-height: 00px; */
    height: auto;
    display: flex;
    justify-content: left;
    align-items: center;
font-family: 'Varela Round', sans-serif;


}


.profileSubmitDiv{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* width: 50; */
}

.profileSubmit{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    margin-left: 48px;
}

.profileSubmit:hover{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
}