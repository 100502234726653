.writingAssistantContainer {
    width: 100%;
    height: 100%;
    display: flex;
    
  }
  
.newChatDiv{
    height: 100%;
    width:20%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px rgba(86, 65, 245, 0.808);
    display: flex;
    flex-direction: column;
}

.buttonsBelowOutputDiv{
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}

.usersAndButtonContainer{
  display: flex;
  align-items: center;
}
.newChatButtonDiv{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.newChatButton{
    width: 105px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.sendToUsersButtonDiv{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100px;
}
.sendToUsersButton{
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: rgba(86, 65, 245, 0.808);
  font-family: 'Varela Round', sans-serif;
  font-weight: 600;
  color: white;
  box-shadow: 1px 1px rgb(5, 5, 5);
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  position: relative;
}

.factCheckHoverDiv{
  position: absolute;
  top: 50px;
  right: 0;
  border-radius: 5px;
  width: 200px;
  background-color: aquamarine;
  padding: 20px;
  font-size: 14px;
}
.sendToUsersButton:hover {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  font-family: 'Varela Round', sans-serif;
  font-weight: 600;
  color: rgba(86, 65, 245, 0.808);
  box-shadow: 1px 1px black;
}

.newChatButtonAssessment{
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: rgba(86, 65, 245, 0.808);
  font-family: 'Varela Round', sans-serif;
  font-weight: 600;
  color: white;
  box-shadow: 1px 1px rgb(5, 5, 5);
  cursor: pointer;
  /* margin-left: 48px; */
  display: flex;
  justify-content: center;
  align-items: center;
position: relative;
}
.AssessmentTitle{
  font-family: 'Varela Round', sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  

}
.submiButtonAssesmentDetailsUploadFileDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
}
.selectedFileName{
  font-size: 14px;
  font-family: 'Varela Round', sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;

}
.assessmentSubmitButtonContainer{
  display: flex;
  height: 100%;
  width: 100%;
justify-content:flex-end ;
align-items: center;
flex-direction: column;
}
.newChatButtonAssessment:hover{
  background-color: white;
  font-family: 'Varela Round', sans-serif;
  font-weight: 600;
  color: rgba(86, 65, 245, 0.808);
  box-shadow: 1px 1px black;
}
.newChatButton:hover{
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
}
.newChatText{
    /* padding-left: 10px; */
}

.factCheckHoverDiv{

}
.newChats{
    width: 100%;
    height: 85%;
}
.chatHistory{
  height: 85%;
  padding: 15px;
}
.chatHistoryList{
  list-style-type: none;
  width: 100%;
  background-color: rgb(240, 237, 237);
  font-size: 15px;
  font-family: 'Varela Round', sans-serif;
height: 40px;
padding-left: 10px;
align-self: center;
border-radius: 5px;
display: flex;
justify-content: flex-start;
align-items: center;
/* margin: 0 auto; */
margin-top: 10px;
}
.AssessmentDetailsContainer{
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;


}

.AssessmentDate{
  width: 100%;
}
  .writingAssistantContainerDiv{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
  .inputFormWrittingAssistant {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Varela Round', sans-serif;
  }
  
  .buttonsBelowOutput {
    width: 150px;
    height: 60px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    /* margin-left: 48px; */
    /* margin-top: 20px; */
  }
  
  .buttonsBelowOutput:hover {
    width: 150px;
    height: 60px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
  }
  .submiButtonWA {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    margin-left: 48px;
  }
  
  .submiButtonWA:hover {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
  }
  .submiButtonAssesmentDetails {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    /* margin-left: 48px; */
  }
  
  .or{
    width: 100%;
    text-align: center;
  }
  .submiButtonAssesmentDetailsUploadFile:hover {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
  }
  .submiButtonAssesmentDetailsUploadFile {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    /* margin-top: 20px; */
    /* margin-left: 48px; */
  }
  .submiButtonAssesmentDetailsRemoveFile:hover {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
  }
  .submiButtonAssesmentDetailsRemoveFile {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    /* margin-top: 20px; */
    /* margin-left: 48px; */
  }
  
  .submiButtonAssesmentDetails:hover {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
  }
  
  .outputDiv {
    background-color: white;
    width: 91%;
    max-height: 630px;
    height: 630px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    margin-top: 30px;
    
    padding: 30px;
    font-family: 'Varela Round', sans-serif;
    box-shadow: 2px 2px rgba(86, 65, 245, 0.808);
    overflow-y: auto; 
  }
  
  .newAssessButtonContainer{
    width: 100%;
    height: 10%;
    /* background-color: black; */
    display: flex;
    justify-content: flex-end;

  }
  .outputIndex {
    padding: 10px;
    border: 1px solid rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px rgba(228, 61, 234, 0.808);
    margin-bottom: 20px;
    border-radius: 5px;
    height: auto;
    width: 100%;
  }
  
  .outputItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .inputUser{
    background-color: rgb(197, 197, 197);
    padding-left: 5px;
   /* margin-right: 10px; */
   border-radius: 5px;
   margin-bottom: 5px;
  }
  .inputOutput{
    padding-left: 5px;
  }
  .outputUser{
    background-color: rgb(197, 197, 197);
    padding-left: 5px;
   /* margin-right: 10px; */
   border-radius: 5px;
   margin-bottom: 5px;
   font-weight: bold;
  }
  
.chatBotOutputStyle{
  font-size: 16px;
  /* font-family: 'Varela Round', sans-serif; */
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  padding-left: 5px;
  font-family: 'Varela Round', sans-serif;

}
  .outputText{
    width: 100%;
    border-radius: 5px;
    border: 2px solid rgba(86, 65, 245, 0.808);
    color: #000;
     margin-bottom: 20px;
  
      padding: 20px;
  }
  .inputQuestion {
    background-color: rgb(243, 243, 245);
    width: 100%;
    min-height: 50px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .inputQuestionP {
    padding: 10px;
  }
  

  
  .copyDivButton {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  .copyButton {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .copyButton:hover {
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
  }
  
  .icon {
  }
  
  .ouputTextP {
    padding: 10px;
  }
  
  .inputContainer {
    position: relative;
    width: 77%;
  }
  
  .inputTextArea {
    width: 100%;
  }
  
  .loadingDotsContainer {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
  
  .loadingDot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    margin-right: 3px;
    animation: loadingAnimation 1s infinite;
  }
  
  @keyframes loadingAnimation {
    0% {
      background-color: rgba(0, 0, 0, 0.6);
    }
    50% {
      background-color: rgba(0, 0, 0, 0.3);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  