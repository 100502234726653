*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: (--font-family);
} 
body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}                                       
   

.sidebar{
  background: white;
  color: black;
  height: 100vh;
}
.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.title{
  font-size: 30px;
}
.main-container{
  background: #EFF3F8;
display: flex;
}
.mainOutlet{
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
 width: 100%;
}
.active{
  border-right: 4px solid black transparent;
  background: #EFF3F8;
}
.top_section{
  display: flex;
  align-items: center;
 padding: 15px 10px;
  justify-content: space-between;
}

.logo{
  font-size: 18px;
  line-height: 0;
  font-family: 'Varela Round', sans-serif;
font-weight: 600;
}

.link{
  display: flex;
  color: black;
  gap: 10px;
  padding: 5px 10px;
  transition: 0.2s cubic-bezier(0.6,-0.28,0.735,0.045);

}

.link:hover{
  border-right: 4px solid rgb(7, 7, 7) transparent;
  transition: 0.2s cubic-bezier(0.6,-0.28,0.735,0.045);
  background: #EFF3F8;
}
.linkText{
  white-space: nowrap;
  font-size: 15px;
  font-family: 'Varela Round', sans-serif;
  
}

.logoutSection{
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  /* align-items: center; */
}
.logoutDiv{
  display: flex;
  color: black;
  gap: 10px;
  padding: 5px 10px;
  transition: 0.2s cubic-bezier(0.6,-0.28,0.735,0.045);
  align-items: center;
  cursor: pointer;
}
a{
  text-decoration: none;
}