.writingAssistantContainer {
    width: 100%;
    height: 100%;
    display: flex;
    
  }
  
.newChatDiv{
    height: 100%;
    width:20%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px rgba(86, 65, 245, 0.808);
    display: flex;
    flex-direction: column;
}

.newChatButtonDiv{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.newChatButton{
    width: 150px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    /* margin-left: 48px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.newChatButton:hover{
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
}
.newChatText{
    padding-left: 10px;
}
.newChats{
    width: 100%;
    height: 85%;
}
.chatHistory{
  height: 85%;
  padding: 15px;
}
.chatHistoryList{
  list-style-type: none;
  width: 100%;
  background-color: rgb(240, 237, 237);
  font-size: 15px;
  font-family: 'Varela Round', sans-serif;
height: 40px;
padding-left: 10px;
align-self: center;
border-radius: 5px;
display: flex;
justify-content: flex-start;
align-items: center;
/* margin: 0 auto; */
margin-top: 10px;
}
  .writingAssistantContainerDiv{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
  .inputFormWrittingAssistant {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Varela Round', sans-serif;
  }
  
  .submiButtonWA {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    margin-left: 48px;
  }
  
  .submiButtonWA:hover {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
  }
  .chatBotOutputStyle{
    font-size: 16px;
  /* font-family: 'Varela Round', sans-serif; */
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  padding-left: 5px;
  font-family: 'Varela Round', sans-serif;
  }
  .outputDiv {
    background-color: white;
    width: 91%;
    max-height: 630px;
    height: 630px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 30px;
    font-family: 'Varela Round', sans-serif;
    box-shadow: 2px 2px rgba(86, 65, 245, 0.808);
    overflow-y: auto; 
  }
  
  .outputIndex {
    padding: 10px;
    border: 1px solid rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px rgba(228, 61, 234, 0.808);
    margin-bottom: 20px;
    border-radius: 5px;
    height: auto;
    width: 100%;
  }
  
  .inputQuestion {
    background-color: rgb(243, 243, 245);
    width: 100%;
    min-height: 50px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .inputQuestionP {
    padding: 10px;
  }
  
  .outputText {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .copyDivButton {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  .copyButton {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .copyButton:hover {
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
  }
  
  .icon {
  }
  
  .ouputTextP {
    padding: 10px;
  }
  
  .inputContainer {
    position: relative;
    width: 77%;
  }
  
  .inputTextArea {
    width: 100%;
  }
  
  .loadingDotsContainer {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
  
  .loadingDot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    margin-right: 3px;
    animation: loadingAnimation 1s infinite;
  }
  
  @keyframes loadingAnimation {
    0% {
      background-color: rgba(0, 0, 0, 0.6);
    }
    50% {
      background-color: rgba(0, 0, 0, 0.3);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  