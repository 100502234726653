.ceuquizContainer{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quizQuestion{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}
.questionSerialNumber{
    height: 500px;
    flex-direction: column;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.questionAnswerBox{
    display: flex;
    flex-direction: column;
    width: 90%;
}

.questionInput{
    height: 50px;
    width: 60%;
    border-radius: 5px;
}

.answerInput{
    height: 50px;
    width: 15%;
    border-radius: 5px;
    margin-top: 10px;
}

.quizAnswer{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    /* flex-direction: column; */
    align-items: center;
}

.submiButtonWARemove{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    margin-top: 10px;
    margin-left: 20px;
}

.submiButtonWARemove:hover{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;

}
.submiButtonWAAddAnswer{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    margin-top: 20px;
    /* margin-left: 20px; */
}

.submiButtonWAAddAnswer:hover{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;

}
.submiButtonRemoveQuestion{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    margin-top: 20px;
    /* margin-left: 20px; */
    margin-bottom: 20px;
}

.submiButtonRemoveQuestion:hover{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;

}
.submiButtonAllQuiz{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    margin-top: 20px;
    /* margin-left: 20px; */
    margin-bottom: 20px;
}

.submiButtonAllQuiz:hover{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;

}

.quizTitleInput{
    margin-bottom: 20px;
}
.QuestionAnswerContainer{
    width: 100%;
    height: 100%;
    display: flex;
}