*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.signupContainer{
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    font-family: 'Varela Round', sans-serif;

}

.loginSignupHeading{
display: flex;
justify-content: center;
align-items: center;
margin: 50px ;
}
.LoginHeading{
    padding-right: 30px;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-size: 22px;
color: white;
}

.SignupHeading{
    padding-left: 30px;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-size: 22px;
color: white;
font-weight: 900;
position: relative;

}

.loginButtonOnSignupPage{
height: 70px;
width: 150px;
font-family: 'Varela Round', sans-serif;

font-size: 22px;
border-radius: 20px;
font-weight: 900;
backdrop-filter: blur(100px);
border: 2px white;
z-index: 200;
position: absolute;

}
.backgroundColorForButton{
    /* background-image: url(../images/Desktop\ -\ 6.png); */
    background-size: cover;
    height: 75px;
    width: 149px;
border-radius: 20px;
left: 200px;

/* margin-top: 10px; */
z-index: 100;
}


.loginFormContainer{
    height: 500px;
    width: 400px;
    top: 176px;
    /* left: 562px; */
    background-color: white;
    border-radius: 30px;
    border: 2px white;
    position: absolute;
    z-index: 100;
    border: 1px grey;
    /* box-shadow: 5px 5px black; */
    /* box-shadow: 50px 50px 50px 50px linear-gradient(to right, red, blue); */
}
.or{
    text-align: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.signupWithGoogleDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.signupWithGoogleButton{
    height: 40px;
    width: 190px;
    border-radius: 10px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px white solid;
    box-shadow: 2px 2px teal;
    
}

.signupLine{
    padding-left: 5px;
    font-family: 'Varela Round', sans-serif;

}
.actualloginFormContainer{
    display: flex;
    justify-content:flex-start;
   margin-top: 50px;
    flex-direction: column;
}
.loginFormheading{
    font-family: 'Varela Round', sans-serif;

    font-size: 20px;
    font-weight: 900;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.emailAddressSection{
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction: column;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 20px;
    left: 0;

}

.emailAddressHeading{
    font-family: 'Varela Round', sans-serif;

}
.emailAddressInput{
width: 360px;
height: 30px;
border-radius: 5px;
font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    margin-top: 10px;
    padding-left: 10px;
}
.loginButtonToActuallyLogInDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginButtonToActuallyLogInButton{
    font-family: 'Varela Round', sans-serif;

    font-size: 16px;
    height: 30px;
    width: 80px;
    border-radius: 5px;
    color: white;
    background-color: black;
}

.forgetPasswordAndSignupSection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.backgroundColorForForm{
    /* background-image: url(../images/Desktop\ -\ 6.png); */
    background-size: cover;
    height: 500px;
    width: 400px;
border-radius: 20px;
/* left: 200px; */
top: 0;
margin-top: 10px;
/* z-index: 100;  */
height: 496px;
}
