.supportContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    font-family: 'Varela Round', sans-serif;

}

.supportInfo{
    font-family: 'Varela Round', sans-serif;
margin-bottom: 10px;
width: 100%;
}

.suppoertInfoHeading1{
    font-family: 'Varela Round', sans-serif;
    margin-bottom: 10px;
    margin-left: 100px;
    margin-top: 10px;
}

.supportInfoQuery{
    margin-left: 100px;
    display: flex;
    
}

.supportSubmitButton{
    display: flex;
    justify-content: center;
    align-items: center;
}