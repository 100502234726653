.newSessionCompContainer{
    width: 98.5%;
    margin-left: 0.6rem;
    border-radius: 5px;
    background-color: white;
    display: flex;

    flex-direction: column;
}

.InputLinesContainer{
    display: flex;
   height: 1000px;
    flex-direction: column;
    width: 100%;
}
.SessionHeadingInput{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

}
.SessionHeadingInputButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
height: 250px;
    margin-top: 20px;
}

.ceuQuizAndCeuQuizPDFcontainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width:45%;
}
.ceuQuizButton{
    width: 150px;
    height:40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
font-family: 'Varela Round', sans-serif;
font-weight: 600;
color: white;
box-shadow: 1px 1px rgb(5, 5, 5);
/* border: 1px rgba(86, 65, 245, 0.808) solid ; */
cursor: pointer;
/* margin-left: 48px; */
/* margin-bottom: 20px; */


}
.ceuQuizButton:hover{
    width: 150px;
    height:40px;
    border-radius: 5px;
    background-color:  white;
font-family: 'Varela Round', sans-serif;
font-weight: 600;
color: rgba(86, 65, 245, 0.808);
box-shadow: 1px 1px black;
}

.AssessmentTitleDivNewSessionCompCeuQuiz{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.AssessmentTitleNewSessionCompCeuQuiz{
    font-family: 'Varela Round', sans-serif;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
  

}
.ceuQuizPdfUploadButtonNewSessionComp{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.submiButtonAssesmentDetailsUploadFileDivNewSessionCompCeuQuiz{
    display: flex;
    justify-content: center;
    align-items: center;
}
.submiButtonAssesmentDetailsUploadFileDivForSecondNewSessionCompCeuQuiz{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    /* height: 80px; */
    margin-left: 10px;
}
.submiButtonAssesmentDetailsUploadFileNewSessionCompCeuQuiz{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    /* margin-top: 20px; */
    margin-left: 10px;
}
.submiButtonAssesmentDetailsRemoveFileNewSessionCompCeuQuiz:hover {
    width: 40px;
    height: 40px;
    
    border-radius: 50%;
    background-color: white;
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: rgba(86, 65, 245, 0.808);
    box-shadow: 1px 1px black;
  }
  .submiButtonAssesmentDetailsRemoveFile {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    /* margin-top: 20px; */
    /* margin-left: 48px; */
  }
.submiButtonAssesmentDetailsRemoveFileNewSessionCompCeuQuiz{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 10px;
    width: 40px;
    height: 40px;
    
    border-radius: 50%;
    background-color: rgba(86, 65, 245, 0.808);
    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    color: white;
    box-shadow: 1px 1px rgb(5, 5, 5);
    cursor: pointer;
    /* margin-top: 20px; */
    /* margin-left: 48px; */
}
.saveNowButton{
    width: 220px;
    height:40px;
    border-radius: 5px;
    background-color: rgba(86, 65, 245, 0.808);
font-family: 'Varela Round', sans-serif;
font-weight: 600;
color: white;
box-shadow: 1px 1px rgb(5, 5, 5);
/* border: 1px rgba(86, 65, 245, 0.808) solid ; */
cursor: pointer;
/* margin-left: 60px; */
margin-top: 27px;

}
.saveNowButton:hover{
    margin-top: 27px;
    border-radius: 5px;
    background-color:  white;
font-family: 'Varela Round', sans-serif;
font-weight: 600;
color: rgba(86, 65, 245, 0.808);
box-shadow: 1px 1px black;
}
.sessionInputLine{
    width: 50%;
    min-height: 200px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
font-family: 'Varela Round', sans-serif;
font-weight: 900;



}
.sessionInputLineDate{
    width: 50%;
    /* min-height: 200px; */
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
font-family: 'Varela Round', sans-serif;
font-weight: 900;



}
