*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.createSessionPageWidth{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.gridSessionwidth{
    width: 100%;
    display: flex;
}
.createNewSessionDiv{
    width: 98.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    height: 50px;
    border-radius: 5px;
    margin-left: 0.6rem;
}

.createNewSessionButtonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}
.createNewSessionLine{
padding-left: 10px;
/* padding-bottom: 3px; */
font-family: 'Varela Round', sans-serif;

}

.linkCompletedSessions{
    width: 100%;
    display: block;
}

.createSessionOutlet{
    margin-top: 20px;
}